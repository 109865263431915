import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import Timer from "../common/Timer";


const UndeliveredOrder=()=> {
    const undelivered_equipment = useSelector(state=>state.undelivered_equipment.undelivered_equipment)
    if (undelivered_equipment && !undelivered_equipment?.length) {
        return <p className="font-weight-bold">Нет заявок</p>
    }
    return (
        <div className="list-group text-left">
            {undelivered_equipment?.map((item,idx)=> (
                <Link to={`/undelivered-equipment/${item.id}`} className='order-link' key={idx}>
                    <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action item-order">
                        <div>
                            <h5>{item?.company?.name}</h5>
                            <p className="font-weight-normal text-dark">{item?.topic === '' ? 'Нет описания':item?.topic?.replace('=','')?.slice(0,50)+'...' }</p>
                        </div>
                        <Timer status={"В работе"} startTime={item?.startTime}/>
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default UndeliveredOrder;
