import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import UndeliveredOrder from "./data-components/UndeliveredOrder";
import {fetchUser} from "../redux/actions";


const UndeliveredEquipment=()=> {

    const dispatch = useDispatch();
    const name = useSelector(state=>state.user.name);

    useEffect(()=> {
        dispatch(fetchUser())
    },[dispatch])

    return (
        <div className='container'>
            <div className="d-flex flex-column">
                <div className="p-1"><h4>{name}, здравствуйте!</h4></div>
                <div className="p-1"><p>Очередь недоставленного оборудования</p></div>
            </div>

            <div className="d-flex justify-content-between">
                <div className="p-2 font-weight-bold">Ваши заявки</div>
                <div className="p-2 font-weight-bold">Ожидает доставки</div>
            </div>
            <UndeliveredOrder/>
        </div>
    )
}

export default UndeliveredEquipment;
