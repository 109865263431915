import React, { useState, useRef } from 'react';
import { fetchEquip, hideLoader, showLoader } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { crmPrivateApi } from '../../redux/axios';
import Qr from "../common/QR";

const EquipmentForm = () => {
    const dispatch = useDispatch();
    const inputRef = useRef([]);
    const [data, setData] = useState({});
    const [qrBlock, setQrBlock] = useState(false);

    const [equip, setEquip] = useState({
        id: '',
        role: '',
        id_teamviewer: ''
    });

    const handleQrCallback = (result) => {
        if (result) {
            setEquip({ ...equip, id: result.text.match(/[\d-]/g).join('') })
            setQrBlock(false)
        }
    }

    const closeHandlerQr = () => {
        setQrBlock(false)
    }

    // получение оборудования по ID
    const getEquipmentId = () => {
        if (equip.id === '') {
            setData({})
            setEquip({ id: '', role: '', id_teamviewer: '' })
            return alert('Введите ID оборудования')
        }
        dispatch(showLoader())

        crmPrivateApi.get('/itsp/device-manage/get', { params: { id: equip.id } })
            .then(response => {
                if (response.data.result.code === 1) {
                    // console.log(response.data)
                    setData(response.data)
                } else {
                    alert(response.data.result.message)
                    setData({})
                    setEquip({ ...equip, role: '', id_teamviewer: '' })
                }
                dispatch(hideLoader())
            }).catch(error => {
                if (error) {
                    alert('Что-то пошло не так!')
                    dispatch(hideLoader())
                }
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {};
        Object.keys(inputRef.current).forEach(item => {
            formData[item] = inputRef.current[item].value;
        });
        // console.log(equip, formData)
        dispatch(fetchEquip(equip, formData))
    }
    // console.log(equip);

    return (
        <>
            <form className="form-group mt-2" id="equipment_data" onSubmit={handleSubmit}>
                {/*<p>В разработке...</p>*/}
                <div className="input-group mb-3 mt-3">
                    <button className="input-group-text qr-button" type="button" onClick={() => setQrBlock(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                            <path d="M2 2h2v2H2V2Z"></path>
                            <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"></path>
                            <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"></path>
                            <path
                                d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"></path>
                            <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"></path>
                        </svg>
                    </button>
                    <input value={equip.id} onChange={(e) => setEquip({ ...equip, id: e.target.value })} name="id_equip" id="id_equip" type="text" className="form-control" placeholder="Введите ID оборудования" />
                    <div className="input-group-append">
                        <button onClick={getEquipmentId} className="btn btn-outline-success" type="button">Отправить</button>
                    </div>
                </div>
                {Object.entries(data).length !== 0 &&
                    <>
                        <div className="form-group text-left">
                            <label htmlFor="company_name">Наименование компании</label>
                            <input value={data.company_name.trim()} disabled={true} className="form-control" type="text" name="company_name" id="company_name" />
                        </div>
                        <div className="form-group text-left">
                            <label htmlFor="equipment_type">Вид оборудования</label>
                            <input value={data.equipment_type.trim()} disabled={true} className="form-control" type="text" name="equipment_type" id="equipment_type" />
                        </div>
                        {data.equipment_type === 'Сервер' &&
                            <div className="form-group text-left">
                                <label htmlFor="teamviewer">ID Teamviewer</label>
                                <input maxLength={20} onChange={(e) => setEquip({ ...equip, id_teamviewer: e.target.value })} value={equip.id_teamviewer} className="form-control" type="text" name="teamviewer" id="teamviewer" required={true} />
                            </div>
                        }
                        <div className="form-group text-left">
                            <label htmlFor="role">Роль</label>
                            <input onChange={(e) => setEquip({ ...equip, role: e.target.value })} value={equip.role} className="form-control" type="text" name="role" id="role" required={true} />
                        </div>

                        {data.equipment_characteristics.length !== 0 &&
                            data.equipment_characteristics.map((item, i) => (
                                <div key={i}>
                                    {Object.keys(item)[0] === 'type' &&
                                        <div className="form-group text-left">
                                            <label htmlFor="exampleFormControlSelect1">{Object.values(item)}</label>
                                            <select ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" id={Object.keys(item)[0]} required={true}>
                                                <option value="">Выберите тип</option>
                                                {data.list_types.map(item => (
                                                    <option value={item.code}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>}
                                    {Object.keys(item)[0] === 'laptop_model' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'cpu' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'operating_system' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'ram' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'hdd_sdd' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'server_name' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'role' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                    {Object.keys(item)[0] === 'name' &&
                                        <div className="form-group text-left">
                                            <label htmlFor={Object.keys(item)[0]}>{Object.values(item)}</label>
                                            <input ref={(element) => {
                                                inputRef.current[Object.keys(item)] = element
                                            }} className="form-control" type="text" name={Object.keys(item)[0]}
                                                id={Object.keys(item)[0]} required={true} />
                                        </div>}
                                </div>

                            ))

                        }
                        <div className="form-group text-left">
                            <button className="btn btn-success mb-4" type="submit">Отправить</button>
                        </div>
                    </>
                }

            </form>
            {qrBlock &&
                <Qr callbackHandler={handleQrCallback} callbackCloseHandler={closeHandlerQr} />
            }
        </>
    )
}

export default EquipmentForm;
