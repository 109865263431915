import {
    AUTHORIZATION, FETCH_CATEGORIES, FETCH_DEPARTMENT_EVENT,
    FETCH_DEPARTMENT_REPAIR, FETCH_EQUIPMENT,
    FETCH_EVENTS, FETCH_LATEST_BID_EVENTS, FETCH_TEMPORARY_EQUIPMENT, FETCH_UNDELIVERED_EQUIPMENT,
    FETCH_USER, FETCH_WORK_COMPLETED,
    HIDE_ALERT,
    HIDE_LOADER,
    SHOW_ALERT,
    SHOW_LOADER
} from "./types";
import axiosApi from '../axiosApi';
import { crmPrivateApi } from "./axios";
// import axiosCrm from '../axiosCrm';

// notification action
export const showAlert = (text) => {
    return {
        type: SHOW_ALERT,
        payload: text
    }
}
export const hideAlert = () => {
    return {
        type: HIDE_ALERT
    }
}
// end

// loader action

export const showLoader = () => {
    return {
        type: SHOW_LOADER
    }
}
export const hideLoader = () => {
    return {
        type: HIDE_LOADER
    }
}
// end

// change status work

export const changeStatusWork = (userHash, imWorking, readyOrder) => {
    return dispatch => {
        dispatch(showLoader())
        crmPrivateApi.post('itsp/mark', {
            ImWorking: imWorking,
            readyOrder: readyOrder
        })
            .then(response => {
                if (response.data.result.code === 1) {
                    window.location.reload()
                    dispatch(hideLoader())
                } else {
                    showAlert('Не удалось обновить, попробуйте еще раз!');
                    dispatch(hideLoader())
                }
            }).catch(function (error) {
                if (error) {
                    showAlert('Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            })
    }
}

// auth action

export const authFetch = (login, password, redirect) => {
    return dispatch => {
        dispatch(showLoader())
        axiosApi.post('/CRM/hs/authorizationFS/method/Login/', {
            login: login.toUpperCase(),
            password: password
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.result.code === 1) {
                    localStorage.setItem('USER_HASH', response.data.hash);
                    dispatch({ type: AUTHORIZATION, payload: localStorage.getItem('USER_HASH') })
                    dispatch(hideLoader())
                    redirect.push("/")
                } else {
                    showAlert('Неверный логин или пароль!');
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    showAlert('Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }
}

// fetch undelivered events
export const fetchUndeliveredEquipment = () => {


    return async dispatch => {
        crmPrivateApi.get('/itsp/undelivired-devices')
            .then(response => {
                if (response.data.result.code === 1) {
                    dispatch({ type: FETCH_UNDELIVERED_EQUIPMENT, payload: response.data.events })
                } else {
                    showAlert('Не загрузились недоставленные оборудования!')
                }
            }).catch(error => {
                if (error) {
                    showAlert('Что-то пошло не так!')
                }
            })
    }
}

// close undelivered events
export const fetchEquipmentOnClose = (id, status, redirect) => {
    return dispatch => {
        dispatch(showLoader())
        crmPrivateApi.post('/itsp/edit-event/set/status', {
            id: id,
            status: status
        })
            .then(response => {
                if (response.data.result.code === 1) {
                    // console.log(response.data)
                    alert('Заявка закрыта!');
                    dispatch(hideLoader())
                    redirect.push("/")
                } else {
                    alert('Что-то пошло не так! Попробуйте снова!');
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    alert('Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }
}

export const fetchLatestBidEvents = (userHash, eventId) => {
    const url = '/CRM/hs/User/previous-events?Hash=' + userHash + '&EventId=' + eventId;
    return dispatch => {

        axiosApi.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: FETCH_LATEST_BID_EVENTS, payload: response.data.events })
            } else {
                showAlert('Загрузка последних заявок неуспешна!')
            }
        }).catch(error => {
            if (error) {
                showAlert('Что-то пошло не так!')
            }
        })
    }
}


// fetch events
export const fetchEvents = (userHash) => {
    return dispatch => {
        dispatch(showLoader())
        crmPrivateApi.get('/itsp/Events')
            .then(response => {
                if (response.data.result.code === 1) {
                    dispatch({ type: FETCH_EVENTS, payload: response.data.events })
                    dispatch(hideLoader())
                } else {
                    showAlert('Загрузка заявок неуспешна!')
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    showAlert('', 'Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }
}

// fetch temporary equipment
export const fetchTemporaryEquipment = (userHash) => {
    return dispatch => {
        crmPrivateApi.get('/itsp/Events')
            .then(response => {
                if (response.data) {
                    dispatch({ type: FETCH_TEMPORARY_EQUIPMENT, payload: response.data.listequipment })
                } else {
                    showAlert('Загрузка данных неуспешна!')
                }
            })
            .catch(function (error) {
                if (error) {
                    showAlert('', 'Что-то пошло не так!');
                }
            });
    }
}

// fetch user
export const fetchUser = () => {

    return dispatch => {
        dispatch(showLoader())
        crmPrivateApi.get('/itsp/Events')
            .then(response => {
                if (response.data) {
                    dispatch({ type: FETCH_USER, payload: response.data })
                    dispatch(hideLoader())
                } else {
                    showAlert('Загрузка данных неуспешна!')
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    showAlert('', 'Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }
}

// получение отделов
export const fetchDepartment = (id) => {
    const url_1 = 'itsp/edit-event/list/projects'
    const url_2 = 'itsp/edit-event/list/queue-users'
    return dispatch => {
        crmPrivateApi.get(id === 1 ? url_1 : url_2)
            .then(response => {
                if (response.data.result.code === 1) {
                    switch (id) {
                        case 1:
                            dispatch({ type: FETCH_DEPARTMENT_REPAIR, payload: response.data.department })
                            break;
                        case 2:
                            dispatch({ type: FETCH_DEPARTMENT_EVENT, payload: response.data.department })
                            break;
                        default: return false;
                    }
                } else {
                    showAlert('Не загрузились данные отделов!')
                }
            }).catch(error => {
                if (error) {
                    showAlert('Что-то пошло не так!')
                }
            })
    }
}

// получение списка для категории "Выставить счет"
export const fetchCategories = () => {
    return dispatch => {
        crmPrivateApi('/itsp/edit-event/list/service-categories')
            .then(response => {
                if (response.data.result.code === 1) {
                    dispatch({ type: FETCH_CATEGORIES, payload: response.data.itemgroup })
                } else {
                    showAlert('Не загрузились категории!')
                }
            }).catch(error => {
                if (error) {
                    showAlert('Что-то пошло не так!')
                }
            })
    }
}

// получение номенклатуры
export const fetchEquipment = ($id) => {
    return dispatch => {
        crmPrivateApi.get(`/itsp/edit-event/list/services?categoryCode=${$id}`)
            .then(response => {
                if (response.data.result.code === 1) {
                    dispatch({ type: FETCH_EQUIPMENT, payload: response.data.equipment })
                } else {
                    showAlert('Не загрузились данные номенклатуры!')
                }
            }).catch(error => {
                if (error) {
                    showAlert('Что-то пошло не так!')
                }
            })
    }

}

// получение категорий выполненных работ
export const fetchWorkCompleted = () => {
    return dispatch => {
        crmPrivateApi.get('/itsp/edit-event/list/categories')
            .then(response => {
                if (response.data.result.code === 1) {
                    dispatch({ type: FETCH_WORK_COMPLETED, payload: response.data.categories })
                } else {
                    showAlert('Не загрузились категории выполненных работ!')
                }
            }).catch(error => {
                if (error) {
                    showAlert('Что-то пошло не так!')
                }
            })
    }
}




// изменение статуса
export const fetchStatus = (id, status, redirect) => {

    return dispatch => {
        dispatch(showLoader())
        crmPrivateApi.post('/itsp/edit-event/set/status', {
            id: id,
            status: status
        })
            .then(response => {
                if (response.data.result.code === 1) {
                    alert('Заявка взята в работу!');
                    dispatch(hideLoader())
                    redirect.push("/")
                } else {
                    alert('Что-то пошло не так! Попробуйте снова!');
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    alert('Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }

}

//  отправляем оборудование
export const fetchEquip = (equip, data) => {
    return dispatch => {
        dispatch(showLoader())

        crmPrivateApi.post('/itsp/device-manage/update', {
            data: {
                id: equip.id,
                id_teamviewer: equip.id_teamviewer,
                role: equip.role,
                equipment_characteristics: data
            }
        })
            .then(response => {
                if (response.data.result.code === 1) {
                    // console.log(response.data)
                    alert('Оборудование успешно отправлено!');
                    dispatch(hideLoader())
                    window.location.reload();
                } else {
                    alert('Оборудование не отправлено, попробуйте еще раз!');
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    alert('Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }
}

// отправка данных (закрытие заявки)
export const fetchData = (data, redirect) => {

    return dispatch => {
        dispatch(showLoader())

        const body = {
            id: data.id,
            description_work: data.description_work,
            work_completed: data.work_completed,
            workConst: data.workConst,
            repairs: +data.repairs,
            list_equipment_repairs: data.list_equipment_repairs,
            invoice: +data.invoice,
            list_equipment_invoice: data.list_equipment_invoice.map((item) => ({ ...item, count: +item.count })),
            kp: +data.kp,
            list_kp: data.list_kp,
            create_event: +data.create_event,
            list_event: data.list_event,
            replacementequipment: +data.replacementequipment,
            list_equipment_replace: data.list_equipment_replace,
            TakeEquipment: +data.TakeEquipment,
            list_equipment_take: data.list_equipment_take,
            education: +data.education,
        }

        crmPrivateApi.post('/itsp/close-event', body)
            .then(response => {
                if (response.data.result.code === 1) {
                    alert('Заявка успешно закрыта!');
                    dispatch(hideLoader())
                    redirect.push("/")
                } else {
                    alert('Заявка не закрыта, попробуйте еще раз!');
                    dispatch(hideLoader())
                }
            })
            .catch(function (error) {
                if (error) {
                    alert('Что-то пошло не так!');
                    dispatch(hideLoader())
                }
            });
    }

}


