import React, {useEffect} from 'react';
import base64 from 'base-64';
import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Timer from "../common/Timer";
import {fetchEvents, fetchStatus} from "../../redux/actions";
import images from '../../2gis.png';
import * as DOMPurify from 'dompurify';

const OrderDetail=()=> {
    let history = useHistory();
    const dispatch = useDispatch();
    const userToken = useSelector(state=> state.auth.userHash);

    useEffect(() => {
        dispatch(fetchEvents(userToken))
    }, [dispatch, userToken]);

    const { id } = useParams();

    if (id.includes('-')) {
        window.location.href = window.location.href.replace(id, base64.encode(id))
    }

    const event = useSelector(state=> state.events.events.filter(item=>item.id===base64.decode(id)))[0]

    if (event === undefined) {
        return '';
    }

    let descriptionHtml = DOMPurify.sanitize(event.description);

    return (
      <div className="container">
          <div className="d-flex flex-column text-left">
              <div className="d-flex flex-row p-2">
                  {event.status === 'В работе' && <p className="font-weight-bold">В работе: <Timer status={event.status} startTime={event.startTime}/> </p> }
              </div>
              <div className="d-flex flex-row p-2">
                  <p className="font-weight-bold">Партнер: <span className="text-order">{event.company.name}</span> </p>
              </div>

              <div className="d-flex">
                  <a className='m-3' href={`${event?.company?.evernote}`}>
                      <button className="btn btn-sm btn-support btn-block" type="submit">Информация по клиенту</button>
                  </a>

                  <a className='m-3' href={`/recent-bid/${event.id}`}>
                      <button className="btn btn-sm btn-support btn-block" type="submit">Последние задачи от клиента</button>
                  </a>
              </div>

              <div className="d-flex flex-row p-2">
                  <p className="font-weight-bold">Тема события: <span className="text-order">{event.topic}</span></p>
              </div>
              <div className="d-flex flex-row p-2">
                  <p className="font-weight-bold">Описание: <span className="text-order" dangerouslySetInnerHTML={{__html: descriptionHtml === '' ? 'Нет описания' : descriptionHtml}}/></p>
              </div>
              <div className="d-flex flex-row p-2">
                  <p className="font-weight-bold">Контактное лицо: <span className="text-order">{event.contact.name}</span></p>
              </div>
              <a className='m-3' href={`tel:${event.contact.phone}`}>
                  <button className="btn btn-lg btn-support btn-block" type="submit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       className="bi bi-telephone mr-2" viewBox="0 0 16 16">
                      <path
                          d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  {event.contact.phone}</button>
              </a>
              <div className="d-flex flex-row p-2">
                  <p className="font-weight-bold">Адрес: <span className="text-order">{event.company.address}</span></p>
              </div>
              <div className="d-flex flex-row p-2">
                  <p className="font-weight-bold">Комментарий: <span className="text-order">{event.company.comment}</span></p>
              </div>
              <a rel="noopener noreferrer" className='mb-4' target="_blank" href={`dgis://2gis.kz/almaty/search/${event.company.address}`}>
                  <img className='img' src={images} alt={"img_2gis"}/>
              </a>
              {event.TakeEquipment &&
                <p className="font-weight-bold text-danger text-center mb-3">Не забудьте временное оборудование!</p>
              }
              {event.status === 'Запланировано' ?
                  <button onClick={()=>dispatch(fetchStatus(event.id,"ВРаботе", history))} className="btn btn-lg btn-support btn-block mb-3" type="submit">Взять в работу</button>
                  :
                  <Link to={`/order-form/${base64.encode(event.id)}`}><button className="btn btn-lg btn-support btn-block mb-3" type="submit">Закрыть заявку</button></Link>
              }

          </div>
      </div>
   )

}

export default OrderDetail

