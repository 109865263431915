import { SET_ACCESS_TOKEN, RESET, SET_ACCESS, SET_OK, SET_REFRESH_TOKEN, SET_USER_DATA } from "./authTypes";
import { crmPrivateApi, crmPublicApi } from "./axios";

export const setAccessToken = (token) => {
    return {
        type: SET_ACCESS_TOKEN,
        payload: token
    }
}

export const setRefreshToken = (token) => {
    return {
        type: SET_REFRESH_TOKEN,
        payload: token
    }
}

export const setAccess = (access) => {
    return {
        type: SET_ACCESS,
        payload: access
    }
}

export const setOk = (ok) => {
    return {
        type: SET_OK,
        payload: ok
    }
}

export const reset = () => {
    return {
        type: RESET
    }
}

export const setUserData = (data) => {
    return {
        type: SET_USER_DATA,
        payload: data
    }
}

export const apiLogin = (login, password) => async dispatch => {
    try {
        // dispatch(setError(false))
        // dispatch(setMessage(''))
        // dispatch(setLoading(true))

        const { data: { access_token, refresh_token } } = await crmPublicApi.post('/auth/signin', { login, password })

        localStorage.setItem('access_token', access_token)
        localStorage.setItem('refresh_token', refresh_token)

        dispatch(setAccessToken(access_token))
        dispatch(setRefreshToken(refresh_token))

        dispatch(setOk(true))
        // dispatch(setMessage(`Добро пожаловать, ${login}`))

        dispatch(setAccess(true))
        // navigate('/', { replace: true })
        // window.location.href = '/';


        dispatch(hasAccess())
        dispatch(reset())
    } catch (e) {
        // const { code } = e.response.data
        // const msg = getErrorMessage(code)

        setTimeout(() => {
            // dispatch(setError(true))
            // dispatch(setMessage(msg))
        }, 2000)
    }
}
export const hasAccess = () => async dispatch => {
    try {
        const response = await crmPrivateApi.get('/auth/access')
        dispatch(setUserData(response.data))
        dispatch(setAccess(true))
    } catch (e) {
        dispatch(setUserData(null))
        dispatch(setAccess(false))
    }
}

