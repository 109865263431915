import axios from 'axios'

export const crmPrivateApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
})
export const crmPublicApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
})

crmPrivateApi.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => Promise.reject(error)
)

crmPrivateApi.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config
        if (error.code === 'ERR_NETWORK') {
            return Promise.reject(error)
        }
        if (error.response) if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                const refreshToken = localStorage.getItem('refresh_token')
                const { data: { access_token } } = await crmPublicApi.post('/auth/refresh', { refreshToken })

                localStorage.setItem('access_token', access_token)

                originalRequest.headers.Authorization = `Bearer ${access_token}`
                return axios(originalRequest)
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }

        return Promise.reject(error)
    }
)




