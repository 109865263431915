import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { authFetch, hideAlert, showAlert } from "../redux/actions";
import { apiLogin } from '../redux/authActions';
import { useSelector } from "react-redux";

const Login = (props) => {

    const access = useSelector(state => state.auth.access)

    useEffect(() => {
        console.log(access)
        if (access) window.location.href = '/';
    }, [access])

    const [inputs, setInputs] = useState({
        login: '',
        password: ''
    });

    const { login, password } = inputs;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (login.trim() === '' || password.trim() === '') {
            props.showAlert('Введите данные!')
            setTimeout(() => {
                props.hideAlert(null)
            }, 3000)
        } else {
            props.apiLogin(login, password)
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    return (
        <div className="d-flex height align-items-center">
            <form className="form-signin" onSubmit={handleSubmit}>
                <h1 className="h3 mb-3 font-weight-normal">Авторизация</h1>
                <input type="text" name="login" value={login} onChange={handleChange} id="login" className="form-control mb-3" />
                <input type="password" name="password" value={password} onChange={handleChange} id="password" className="form-control mb-3" />
                <button className="btn btn-lg btn-support btn-block" type="submit">Войти</button>
            </form>
        </div>
    )
}

const mapDispatchToProps = {
    showAlert,
    authFetch,
    hideAlert,
    apiLogin,
}


export default connect(null, mapDispatchToProps)(Login);
