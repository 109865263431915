import React, { useEffect, useState } from 'react';
import Login from "./components/Login";
import Home from "./components/Home";
import OrderDetail from "./components/data-components/OrderDetail";
import OrderForm from "./components/data-components/OrderForm";
import NoMatch from "./components/404Page";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Notification from "./components/common/Notification";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "./components/common/Loader";
import UndeliveredEquipment from "./components/UndeliveredEquipment";
import UndeliveredOrderDetail from "./components/data-components/UndeliveredOrderDetail";
import TemporaryEquipment from "./components/TemporaryEquipment";
import LatestBid from "./components/LatestBid";
import LatestBidDetail from "./components/data-components/LatestBidDetail";
import { hasAccess } from './redux/authActions';
import { Spin } from 'antd';

function App(props) {
    const loading = useSelector(state => state.loader.loading)
    const access = useSelector(state => state.auth.access)

    const [isLoaded, setIsLoaded] = useState(false)

    const dispatch = useDispatch()


    useEffect(() => {
        async function fetchData() {
            setIsLoaded(false)
            await dispatch(hasAccess())
            setIsLoaded(true)
        }

        fetchData()
    }, [dispatch])

    if (!isLoaded) return <Spin fullscreen />

    return (

        <>
            {loading && <Loader />}

            <BrowserRouter>
                <div className='cover_container mx-auto height'>
                    {props.notification && <Notification text={props.notification} />}
                    {!access ? (
                        <Switch>
                            <Route path="/login" exact component={Login} />
                            <Redirect to="/login" />
                        </Switch>
                    ) : (

                        <Switch>
                            <Route path="/login" exact component={Login} />
                            <Route path="/" exact component={Home} />
                            <Route path="/temporary-equipment" exact component={TemporaryEquipment} />
                            <Route path="/undelivered-equipment" exact component={UndeliveredEquipment} />
                            <Route path="/recent-bid/:id" exact component={LatestBid} />
                            <Route path="/recent-bid-detail/:parentId/:id" exact component={LatestBidDetail} />
                            <Route path="/undelivered-equipment/:id" exact component={UndeliveredOrderDetail} />
                            <Route path="/order-detail/:id" exact component={OrderDetail} />
                            <Route path="/order-form/:id" exact component={OrderForm} />
                            <Route path="*" component={NoMatch} />
                        </Switch>
                    )}
                </div>

            </BrowserRouter>

        </>
    );
}

const mapStateToProps = state => {
    return {
        notification: state.notification.message,
        userHash: state.auth.userHash,
    }
}
export default connect(mapStateToProps, null)(App);