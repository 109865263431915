import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEquipmentOnClose, fetchUndeliveredEquipment } from "../../redux/actions";
import Timer from "../common/Timer";


const UndeliveredOrderDetail = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(fetchUndeliveredEquipment())
    }, [dispatch]);

    const event = useSelector(state => state.undelivered_equipment.undelivered_equipment.filter(item => item.id === id))[0]

    if (event === undefined) {
        return ''
    }

    return (
        <div className="container">
            <div className="d-flex flex-column text-left">

                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">В работе: <Timer status={"В работе"} startTime={event.startTime} /> </p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Партнер: <span className="text-order">{event.company.name.trim()}</span> </p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Тема события: <span className="text-order">{event.topic.trim()}</span></p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Описание: <span className="text-order">{typeof event.description == 'string' ? (event.description.trim() === '' ? 'Нет описания' : event.description.trim()) : 'Нет описания'}</span></p>
                </div>
                <div className="d-flex flex-row p-2">
                    <p className="font-weight-bold">Контактное лицо: <span className="text-order">{event.contact?.name}</span></p>
                </div>
                <button onClick={() => dispatch(fetchEquipmentOnClose(event.id, "ПеренаправитьОборудование", history))} className="btn btn-lg btn-support btn-block mb-3" type="submit">Оборудование доставлено</button>
            </div>
        </div>
    )
}

export default UndeliveredOrderDetail;
