import { SET_ACCESS_TOKEN, RESET, SET_ACCESS, SET_OK, SET_REFRESH_TOKEN, SET_USER_DATA } from "./authTypes";
import { AUTHORIZATION } from "./types";

let userHash = localStorage.getItem('USER_HASH');
// console.log(userHash)
const initialState = {
    userHash,
    access_token: '',
    refresh_token: '',
    access: false,
    ok: false,
    loading: false,
    error: false,
    message: '',
    userData: {
        first_name: '',
        last_name: '',
        middle_name: '',
        it: '',
        name_prefix: '',
    }
}
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHORIZATION:
            return { ...state, userHash: action.payload }
        case SET_ACCESS_TOKEN:
            return { ...state, access_token: action.payload }
        case SET_REFRESH_TOKEN:
            return { ...state, refresh_token: action.payload }
        case SET_ACCESS:
            return { ...state, access: action.payload }
        case SET_OK:
            return { ...state, ok: action.payload }
        case RESET:
            return { ...state, loading: false, error: false, ok: false, message: '', }
        case SET_USER_DATA:
            return {
                ...state,
                userData: {
                    first_name: action.payload?.first_name,
                    last_name: action.payload?.last_name,
                    middle_name: action.payload?.middle_name,
                    it: action.payload?.it,
                    name_prefix: action.payload?.name_prefix,
                }
            }
        default:
            return state;
    }
}

